import React from "react";
import { Link } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";

const PreferredHotelResort = (props) => {

    return (
        <>

            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .page--cus {
                        padding: 20px 40px;
                    }

                    .admin-setting {
                        padding: 0px;
                    }
                    .page--cus--banner{
                        margin-bottom: 40px;
                    }
                `,
                }}></style>
            <Layout location={props.location}>
                <SEO title="Preferred Hotel Resort " />

                <div className="page--cus">
                    <div style={{ textAlign: "center" }}>
                        <img alt="" src="https://resource.ayana.com/live/rewards/page/AYANA_MIdplaza_Jakarta_Lobby.jpg" style={{ width: "1599px", height: "387px" }} />
                        <br />
                        <br />
                        <br />
                        <span style={{ textAlign: "center" }}>AYANA Midplaza, JAKARTA is now affiliated with Preferred Hotels &amp; Resorts.</span>
                        <br style={{ textAlign: "center" }} />
                        <span style={{ textAlign: "center" }}>AYANA Rewards member can redeem their AYANA Rewards points to all Preferred Hotels &amp; Resorts properties at more than 700 hotels in over 85 countries.</span>
                        <br style={{ textAlign: "center" }} />
                        <br style={{ textAlign: "center" }} />
                        <span style={{ textAlign: "center" }}>Redeem your AYANA Rewards points to&nbsp;</span>Preferred Hotels &amp; Resorts iPrefer gift certificate.
                    </div>
                    <div style={{ textAlign: "center" }}>
                        1,500 AYANA Rewards points for US$25 iPrefer gift certificate
                    </div>
                    <div style={{ textAlign: "center" }}>
                        3,000 AYANA Rewards points for US$50 iPrefer gift certificate
                    </div>
                    <div style={{ textAlign: "center" }}>
                        6,000 AYANA Rewards points for US$100 iPrefer gift certificate
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <br style={{ textAlign: "center" }} />
                        <span style={{ textAlign: "center" }}>Discover list of&nbsp;</span>Preferred Hotels &amp; Resorts affiliation though this <strong><a href="https://preferredhotels.com/page/travel-guides" target="_blank"><u>link</u></a></strong>.<br />
                        <span style={{ textAlign: "center" }}>For more info please email through info@ayanarewards.com</span>
                    </div>
                </div>
                <br />

            </Layout>
        </>
    )
}
export default PreferredHotelResort